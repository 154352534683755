import { default as _91_46_46_46all_93OzNE4gLHmgMeta } from "/vercel/path0/apps/front2/pages/[...all].vue?macro=true";
import { default as aboutDt1k9dcGqGMeta } from "/vercel/path0/apps/front2/pages/about.vue?macro=true";
import { default as aboutUsQI2mxHQSX4Meta } from "/vercel/path0/apps/front2/pages/aboutUs.vue?macro=true";
import { default as index0l5wJkuzRjMeta } from "/vercel/path0/apps/front2/pages/account/addresses/[id]/index.vue?macro=true";
import { default as indexE0QDSwzMGCMeta } from "/vercel/path0/apps/front2/pages/account/addresses/index.vue?macro=true";
import { default as indexG7URHNB5qjMeta } from "/vercel/path0/apps/front2/pages/account/alerts/index.vue?macro=true";
import { default as indexQKQQZwsrRdMeta } from "/vercel/path0/apps/front2/pages/account/cashbackWallets/index.vue?macro=true";
import { default as index9H6q5m34slMeta } from "/vercel/path0/apps/front2/pages/account/cashWallets/index.vue?macro=true";
import { default as indexsp4oUfmzDdMeta } from "/vercel/path0/apps/front2/pages/account/claimVouchers/index.vue?macro=true";
import { default as indexAiaGKjxDIXMeta } from "/vercel/path0/apps/front2/pages/account/commWallets/index.vue?macro=true";
import { default as indexZyjGcdh3rPMeta } from "/vercel/path0/apps/front2/pages/account/creditSlips/index.vue?macro=true";
import { default as indexsJkfk6WlTuMeta } from "/vercel/path0/apps/front2/pages/account/favoriteProducts/index.vue?macro=true";
import { default as indexmyLoAorQUZMeta } from "/vercel/path0/apps/front2/pages/account/index.vue?macro=true";
import { default as index6t33cODZEqMeta } from "/vercel/path0/apps/front2/pages/account/loyaltyPoints/index.vue?macro=true";
import { default as index2RDQj30fDSMeta } from "/vercel/path0/apps/front2/pages/account/merchandiseReturns/index.vue?macro=true";
import { default as index0rHtOwZZY0Meta } from "/vercel/path0/apps/front2/pages/account/orderHistories/[channelOrderId]/index.vue?macro=true";
import { default as indexQPXiPlWVkkMeta } from "/vercel/path0/apps/front2/pages/account/orderHistories/[channelOrderId]/invoice/[invoiceId]/index.vue?macro=true";
import { default as indexdrHk7CVBTwMeta } from "/vercel/path0/apps/front2/pages/account/orderHistories/index.vue?macro=true";
import { default as indexRd9Ye5v5PjMeta } from "/vercel/path0/apps/front2/pages/account/personalInformation/index.vue?macro=true";
import { default as indexqkcaJlFmkSMeta } from "/vercel/path0/apps/front2/pages/account/profile/index.vue?macro=true";
import { default as index1WIYGcsiK4Meta } from "/vercel/path0/apps/front2/pages/account/salesReports/[orderreport_id]/index.vue?macro=true";
import { default as index9sbooT5wtbMeta } from "/vercel/path0/apps/front2/pages/account/salesReports/index.vue?macro=true";
import { default as indexL0wDnYvouHMeta } from "/vercel/path0/apps/front2/pages/account/vouchers/index.vue?macro=true";
import { default as accountx935BZYZwuMeta } from "/vercel/path0/apps/front2/pages/account.vue?macro=true";
import { default as indexZhHeNjgv55Meta } from "/vercel/path0/apps/front2/pages/b/outlet/index.vue?macro=true";
import { default as indexLwpd9P9ZryMeta } from "/vercel/path0/apps/front2/pages/c/[id]/[id2]/[id3]/[id4]/index.vue?macro=true";
import { default as indexPgYxTfxfajMeta } from "/vercel/path0/apps/front2/pages/c/[id]/[id2]/[id3]/index.vue?macro=true";
import { default as indexMbNLqxYmd9Meta } from "/vercel/path0/apps/front2/pages/c/[id]/[id2]/index.vue?macro=true";
import { default as indexJori8BchhuMeta } from "/vercel/path0/apps/front2/pages/c/[id]/index.vue?macro=true";
import { default as index63X5boMKIyMeta } from "/vercel/path0/apps/front2/pages/c/ce/index.vue?macro=true";
import { default as indexBaAe1tiHITMeta } from "/vercel/path0/apps/front2/pages/c/iluvmyface/index.vue?macro=true";
import { default as indexhdhtlzwDu7Meta } from "/vercel/path0/apps/front2/pages/c/sales/index.vue?macro=true";
import { default as indexQ1xEDEPHcEMeta } from "/vercel/path0/apps/front2/pages/c/skincare/index.vue?macro=true";
import { default as indexdULOikpJaKMeta } from "/vercel/path0/apps/front2/pages/c/skinTherapies/index.vue?macro=true";
import { default as indexKg8zvIa0wHMeta } from "/vercel/path0/apps/front2/pages/c/spaWellness/index.vue?macro=true";
import { default as indexY8LsX5qpkEMeta } from "/vercel/path0/apps/front2/pages/c/therapies/index.vue?macro=true";
import { default as indexFZ46fcrpi7Meta } from "/vercel/path0/apps/front2/pages/cart/index.vue?macro=true";
import { default as catalogueGedVjLBASkMeta } from "/vercel/path0/apps/front2/pages/catalogue.vue?macro=true";
import { default as indexSoSmltHuwuMeta } from "/vercel/path0/apps/front2/pages/checkout/index.vue?macro=true";
import { default as paymentdza2Cg5WMfMeta } from "/vercel/path0/apps/front2/pages/checkout/payment.vue?macro=true";
import { default as successvLFry5vvdNMeta } from "/vercel/path0/apps/front2/pages/checkout/success.vue?macro=true";
import { default as contactn86EENxh3HMeta } from "/vercel/path0/apps/front2/pages/contact.vue?macro=true";
import { default as deliveryPolicyXMazQGrmYuMeta } from "/vercel/path0/apps/front2/pages/deliveryPolicy.vue?macro=true";
import { default as indexDaCA8EY6VlMeta } from "/vercel/path0/apps/front2/pages/favorites/index.vue?macro=true";
import { default as indexG2Hom0pSxnMeta } from "/vercel/path0/apps/front2/pages/index.vue?macro=true";
import { default as indexoB3FwamzokMeta } from "/vercel/path0/apps/front2/pages/login/index.vue?macro=true";
import { default as reset_45doHP7aU9ROyIMeta } from "/vercel/path0/apps/front2/pages/login/reset-do.vue?macro=true";
import { default as resetJQtWwMIZNhMeta } from "/vercel/path0/apps/front2/pages/login/reset.vue?macro=true";
import { default as logoutAeIVSfFEURMeta } from "/vercel/path0/apps/front2/pages/logout.vue?macro=true";
import { default as _202406_45shipmentalfEtJcQQvMeta } from "/vercel/path0/apps/front2/pages/news/202406-shipment.vue?macro=true";
import { default as cancelkr5VMQWZ4bMeta } from "/vercel/path0/apps/front2/pages/payment/cancel.vue?macro=true";
import { default as success49LJgJYvfwMeta } from "/vercel/path0/apps/front2/pages/payment/success.vue?macro=true";
import { default as privacyPolicywluBvwE0GrMeta } from "/vercel/path0/apps/front2/pages/privacyPolicy.vue?macro=true";
import { default as _91id_93XYSa8RkW9MMeta } from "/vercel/path0/apps/front2/pages/products/[id].vue?macro=true";
import { default as indexy3p1NavYh9Meta } from "/vercel/path0/apps/front2/pages/products/index.vue?macro=true";
import { default as indexdbwMCTKwHmMeta } from "/vercel/path0/apps/front2/pages/register/index.vue?macro=true";
import { default as returnPolicyy6Td5DHFP3Meta } from "/vercel/path0/apps/front2/pages/returnPolicy.vue?macro=true";
import { default as salestrKC3LrFUzMeta } from "/vercel/path0/apps/front2/pages/sales.vue?macro=true";
import { default as indexnRITrVonnxMeta } from "/vercel/path0/apps/front2/pages/search/index.vue?macro=true";
import { default as termsOfUseegdIcZwAi1Meta } from "/vercel/path0/apps/front2/pages/termsOfUse.vue?macro=true";
import { default as tipsAdvicepOyZQ1ZgHmMeta } from "/vercel/path0/apps/front2/pages/tipsAdvice.vue?macro=true";
export default [
  {
    name: _91_46_46_46all_93OzNE4gLHmgMeta?.name ?? "all",
    path: _91_46_46_46all_93OzNE4gLHmgMeta?.path ?? "/:all(.*)*",
    meta: { ...(_91_46_46_46all_93OzNE4gLHmgMeta || {}), ...{"middleware":["client"]} },
    alias: _91_46_46_46all_93OzNE4gLHmgMeta?.alias || [],
    redirect: _91_46_46_46all_93OzNE4gLHmgMeta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: aboutDt1k9dcGqGMeta?.name ?? "about",
    path: aboutDt1k9dcGqGMeta?.path ?? "/about",
    meta: { ...(aboutDt1k9dcGqGMeta || {}), ...{"middleware":["client"]} },
    alias: aboutDt1k9dcGqGMeta?.alias || [],
    redirect: aboutDt1k9dcGqGMeta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/about.vue").then(m => m.default || m)
  },
  {
    name: aboutUsQI2mxHQSX4Meta?.name ?? "aboutUs",
    path: aboutUsQI2mxHQSX4Meta?.path ?? "/aboutUs",
    meta: { ...(aboutUsQI2mxHQSX4Meta || {}), ...{"middleware":["client"]} },
    alias: aboutUsQI2mxHQSX4Meta?.alias || [],
    redirect: aboutUsQI2mxHQSX4Meta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/aboutUs.vue").then(m => m.default || m)
  },
  {
    name: accountx935BZYZwuMeta?.name ?? undefined,
    path: accountx935BZYZwuMeta?.path ?? "/account",
    meta: { ...(accountx935BZYZwuMeta || {}), ...{"middleware":["client"]} },
    alias: accountx935BZYZwuMeta?.alias || [],
    redirect: accountx935BZYZwuMeta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: index0l5wJkuzRjMeta?.name ?? "account-addresses-id",
    path: index0l5wJkuzRjMeta?.path ?? "addresses/:id()",
    meta: { ...(index0l5wJkuzRjMeta || {}), ...{"middleware":["client"]} },
    alias: index0l5wJkuzRjMeta?.alias || [],
    redirect: index0l5wJkuzRjMeta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/account/addresses/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexE0QDSwzMGCMeta?.name ?? "account-addresses",
    path: indexE0QDSwzMGCMeta?.path ?? "addresses",
    meta: { ...(indexE0QDSwzMGCMeta || {}), ...{"middleware":["client"]} },
    alias: indexE0QDSwzMGCMeta?.alias || [],
    redirect: indexE0QDSwzMGCMeta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/account/addresses/index.vue").then(m => m.default || m)
  },
  {
    name: indexG7URHNB5qjMeta?.name ?? "account-alerts",
    path: indexG7URHNB5qjMeta?.path ?? "alerts",
    meta: { ...(indexG7URHNB5qjMeta || {}), ...{"middleware":["client"]} },
    alias: indexG7URHNB5qjMeta?.alias || [],
    redirect: indexG7URHNB5qjMeta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/account/alerts/index.vue").then(m => m.default || m)
  },
  {
    name: indexQKQQZwsrRdMeta?.name ?? "account-cashbackWallets",
    path: indexQKQQZwsrRdMeta?.path ?? "cashbackWallets",
    meta: { ...(indexQKQQZwsrRdMeta || {}), ...{"middleware":["client"]} },
    alias: indexQKQQZwsrRdMeta?.alias || [],
    redirect: indexQKQQZwsrRdMeta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/account/cashbackWallets/index.vue").then(m => m.default || m)
  },
  {
    name: index9H6q5m34slMeta?.name ?? "account-cashWallets",
    path: index9H6q5m34slMeta?.path ?? "cashWallets",
    meta: { ...(index9H6q5m34slMeta || {}), ...{"middleware":["client"]} },
    alias: index9H6q5m34slMeta?.alias || [],
    redirect: index9H6q5m34slMeta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/account/cashWallets/index.vue").then(m => m.default || m)
  },
  {
    name: indexsp4oUfmzDdMeta?.name ?? "account-claimVouchers",
    path: indexsp4oUfmzDdMeta?.path ?? "claimVouchers",
    meta: { ...(indexsp4oUfmzDdMeta || {}), ...{"middleware":["client"]} },
    alias: indexsp4oUfmzDdMeta?.alias || [],
    redirect: indexsp4oUfmzDdMeta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/account/claimVouchers/index.vue").then(m => m.default || m)
  },
  {
    name: indexAiaGKjxDIXMeta?.name ?? "account-commWallets",
    path: indexAiaGKjxDIXMeta?.path ?? "commWallets",
    meta: { ...(indexAiaGKjxDIXMeta || {}), ...{"middleware":["client"]} },
    alias: indexAiaGKjxDIXMeta?.alias || [],
    redirect: indexAiaGKjxDIXMeta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/account/commWallets/index.vue").then(m => m.default || m)
  },
  {
    name: indexZyjGcdh3rPMeta?.name ?? "account-creditSlips",
    path: indexZyjGcdh3rPMeta?.path ?? "creditSlips",
    meta: { ...(indexZyjGcdh3rPMeta || {}), ...{"middleware":["client"]} },
    alias: indexZyjGcdh3rPMeta?.alias || [],
    redirect: indexZyjGcdh3rPMeta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/account/creditSlips/index.vue").then(m => m.default || m)
  },
  {
    name: indexsJkfk6WlTuMeta?.name ?? "account-favoriteProducts",
    path: indexsJkfk6WlTuMeta?.path ?? "favoriteProducts",
    meta: { ...(indexsJkfk6WlTuMeta || {}), ...{"middleware":["client"]} },
    alias: indexsJkfk6WlTuMeta?.alias || [],
    redirect: indexsJkfk6WlTuMeta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/account/favoriteProducts/index.vue").then(m => m.default || m)
  },
  {
    name: indexmyLoAorQUZMeta?.name ?? "account",
    path: indexmyLoAorQUZMeta?.path ?? "",
    meta: { ...(indexmyLoAorQUZMeta || {}), ...{"middleware":["client"]} },
    alias: indexmyLoAorQUZMeta?.alias || [],
    redirect: indexmyLoAorQUZMeta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: index6t33cODZEqMeta?.name ?? "account-loyaltyPoints",
    path: index6t33cODZEqMeta?.path ?? "loyaltyPoints",
    meta: { ...(index6t33cODZEqMeta || {}), ...{"middleware":["client"]} },
    alias: index6t33cODZEqMeta?.alias || [],
    redirect: index6t33cODZEqMeta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/account/loyaltyPoints/index.vue").then(m => m.default || m)
  },
  {
    name: index2RDQj30fDSMeta?.name ?? "account-merchandiseReturns",
    path: index2RDQj30fDSMeta?.path ?? "merchandiseReturns",
    meta: { ...(index2RDQj30fDSMeta || {}), ...{"middleware":["client"]} },
    alias: index2RDQj30fDSMeta?.alias || [],
    redirect: index2RDQj30fDSMeta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/account/merchandiseReturns/index.vue").then(m => m.default || m)
  },
  {
    name: index0rHtOwZZY0Meta?.name ?? "account-orderHistories-channelOrderId",
    path: index0rHtOwZZY0Meta?.path ?? "orderHistories/:channelOrderId()",
    meta: { ...(index0rHtOwZZY0Meta || {}), ...{"middleware":["client"]} },
    alias: index0rHtOwZZY0Meta?.alias || [],
    redirect: index0rHtOwZZY0Meta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/account/orderHistories/[channelOrderId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexQPXiPlWVkkMeta?.name ?? "account-orderHistories-channelOrderId-invoice-invoiceId",
    path: indexQPXiPlWVkkMeta?.path ?? "orderHistories/:channelOrderId()/invoice/:invoiceId()",
    meta: { ...(indexQPXiPlWVkkMeta || {}), ...{"middleware":["client"]} },
    alias: indexQPXiPlWVkkMeta?.alias || [],
    redirect: indexQPXiPlWVkkMeta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/account/orderHistories/[channelOrderId]/invoice/[invoiceId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexdrHk7CVBTwMeta?.name ?? "account-orderHistories",
    path: indexdrHk7CVBTwMeta?.path ?? "orderHistories",
    meta: { ...(indexdrHk7CVBTwMeta || {}), ...{"middleware":["client"]} },
    alias: indexdrHk7CVBTwMeta?.alias || [],
    redirect: indexdrHk7CVBTwMeta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/account/orderHistories/index.vue").then(m => m.default || m)
  },
  {
    name: indexRd9Ye5v5PjMeta?.name ?? "account-personalInformation",
    path: indexRd9Ye5v5PjMeta?.path ?? "personalInformation",
    meta: { ...(indexRd9Ye5v5PjMeta || {}), ...{"middleware":["client"]} },
    alias: indexRd9Ye5v5PjMeta?.alias || [],
    redirect: indexRd9Ye5v5PjMeta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/account/personalInformation/index.vue").then(m => m.default || m)
  },
  {
    name: indexqkcaJlFmkSMeta?.name ?? "account-profile",
    path: indexqkcaJlFmkSMeta?.path ?? "profile",
    meta: { ...(indexqkcaJlFmkSMeta || {}), ...{"middleware":["client"]} },
    alias: indexqkcaJlFmkSMeta?.alias || [],
    redirect: indexqkcaJlFmkSMeta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/account/profile/index.vue").then(m => m.default || m)
  },
  {
    name: index1WIYGcsiK4Meta?.name ?? "account-salesReports-orderreport_id",
    path: index1WIYGcsiK4Meta?.path ?? "salesReports/:orderreport_id()",
    meta: { ...(index1WIYGcsiK4Meta || {}), ...{"middleware":["client"]} },
    alias: index1WIYGcsiK4Meta?.alias || [],
    redirect: index1WIYGcsiK4Meta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/account/salesReports/[orderreport_id]/index.vue").then(m => m.default || m)
  },
  {
    name: index9sbooT5wtbMeta?.name ?? "account-salesReports",
    path: index9sbooT5wtbMeta?.path ?? "salesReports",
    meta: { ...(index9sbooT5wtbMeta || {}), ...{"middleware":["client"]} },
    alias: index9sbooT5wtbMeta?.alias || [],
    redirect: index9sbooT5wtbMeta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/account/salesReports/index.vue").then(m => m.default || m)
  },
  {
    name: indexL0wDnYvouHMeta?.name ?? "account-vouchers",
    path: indexL0wDnYvouHMeta?.path ?? "vouchers",
    meta: { ...(indexL0wDnYvouHMeta || {}), ...{"middleware":["client"]} },
    alias: indexL0wDnYvouHMeta?.alias || [],
    redirect: indexL0wDnYvouHMeta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/account/vouchers/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexZhHeNjgv55Meta?.name ?? "b-outlet",
    path: indexZhHeNjgv55Meta?.path ?? "/b/outlet",
    meta: { ...(indexZhHeNjgv55Meta || {}), ...{"middleware":["client"]} },
    alias: indexZhHeNjgv55Meta?.alias || [],
    redirect: indexZhHeNjgv55Meta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/b/outlet/index.vue").then(m => m.default || m)
  },
  {
    name: indexLwpd9P9ZryMeta?.name ?? "c-id-id2-id3-id4",
    path: indexLwpd9P9ZryMeta?.path ?? "/c/:id()/:id2()/:id3()/:id4()",
    meta: { ...(indexLwpd9P9ZryMeta || {}), ...{"middleware":["client"]} },
    alias: indexLwpd9P9ZryMeta?.alias || [],
    redirect: indexLwpd9P9ZryMeta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/c/[id]/[id2]/[id3]/[id4]/index.vue").then(m => m.default || m)
  },
  {
    name: indexPgYxTfxfajMeta?.name ?? "c-id-id2-id3",
    path: indexPgYxTfxfajMeta?.path ?? "/c/:id()/:id2()/:id3()",
    meta: { ...(indexPgYxTfxfajMeta || {}), ...{"middleware":["client"]} },
    alias: indexPgYxTfxfajMeta?.alias || [],
    redirect: indexPgYxTfxfajMeta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/c/[id]/[id2]/[id3]/index.vue").then(m => m.default || m)
  },
  {
    name: indexMbNLqxYmd9Meta?.name ?? "c-id-id2",
    path: indexMbNLqxYmd9Meta?.path ?? "/c/:id()/:id2()",
    meta: { ...(indexMbNLqxYmd9Meta || {}), ...{"middleware":["client"]} },
    alias: indexMbNLqxYmd9Meta?.alias || [],
    redirect: indexMbNLqxYmd9Meta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/c/[id]/[id2]/index.vue").then(m => m.default || m)
  },
  {
    name: indexJori8BchhuMeta?.name ?? "c-id",
    path: indexJori8BchhuMeta?.path ?? "/c/:id()",
    meta: { ...(indexJori8BchhuMeta || {}), ...{"middleware":["client"]} },
    alias: indexJori8BchhuMeta?.alias || [],
    redirect: indexJori8BchhuMeta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/c/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index63X5boMKIyMeta?.name ?? "c-ce",
    path: index63X5boMKIyMeta?.path ?? "/c/ce",
    meta: { ...(index63X5boMKIyMeta || {}), ...{"middleware":["client"]} },
    alias: index63X5boMKIyMeta?.alias || [],
    redirect: index63X5boMKIyMeta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/c/ce/index.vue").then(m => m.default || m)
  },
  {
    name: indexBaAe1tiHITMeta?.name ?? "c-iluvmyface",
    path: indexBaAe1tiHITMeta?.path ?? "/c/iluvmyface",
    meta: { ...(indexBaAe1tiHITMeta || {}), ...{"middleware":["client"]} },
    alias: indexBaAe1tiHITMeta?.alias || [],
    redirect: indexBaAe1tiHITMeta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/c/iluvmyface/index.vue").then(m => m.default || m)
  },
  {
    name: indexhdhtlzwDu7Meta?.name ?? "c-sales",
    path: indexhdhtlzwDu7Meta?.path ?? "/c/sales",
    meta: { ...(indexhdhtlzwDu7Meta || {}), ...{"middleware":["client"]} },
    alias: indexhdhtlzwDu7Meta?.alias || [],
    redirect: indexhdhtlzwDu7Meta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/c/sales/index.vue").then(m => m.default || m)
  },
  {
    name: indexQ1xEDEPHcEMeta?.name ?? "c-skincare",
    path: indexQ1xEDEPHcEMeta?.path ?? "/c/skincare",
    meta: { ...(indexQ1xEDEPHcEMeta || {}), ...{"middleware":["client"]} },
    alias: indexQ1xEDEPHcEMeta?.alias || [],
    redirect: indexQ1xEDEPHcEMeta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/c/skincare/index.vue").then(m => m.default || m)
  },
  {
    name: indexdULOikpJaKMeta?.name ?? "c-skinTherapies",
    path: indexdULOikpJaKMeta?.path ?? "/c/skinTherapies",
    meta: { ...(indexdULOikpJaKMeta || {}), ...{"middleware":["client"]} },
    alias: indexdULOikpJaKMeta?.alias || [],
    redirect: indexdULOikpJaKMeta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/c/skinTherapies/index.vue").then(m => m.default || m)
  },
  {
    name: indexKg8zvIa0wHMeta?.name ?? "c-spaWellness",
    path: indexKg8zvIa0wHMeta?.path ?? "/c/spaWellness",
    meta: { ...(indexKg8zvIa0wHMeta || {}), ...{"middleware":["client"]} },
    alias: indexKg8zvIa0wHMeta?.alias || [],
    redirect: indexKg8zvIa0wHMeta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/c/spaWellness/index.vue").then(m => m.default || m)
  },
  {
    name: indexY8LsX5qpkEMeta?.name ?? "c-therapies",
    path: indexY8LsX5qpkEMeta?.path ?? "/c/therapies",
    meta: { ...(indexY8LsX5qpkEMeta || {}), ...{"middleware":["client"]} },
    alias: indexY8LsX5qpkEMeta?.alias || [],
    redirect: indexY8LsX5qpkEMeta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/c/therapies/index.vue").then(m => m.default || m)
  },
  {
    name: indexFZ46fcrpi7Meta?.name ?? "cart",
    path: indexFZ46fcrpi7Meta?.path ?? "/cart",
    meta: { ...(indexFZ46fcrpi7Meta || {}), ...{"middleware":["client"]} },
    alias: indexFZ46fcrpi7Meta?.alias || [],
    redirect: indexFZ46fcrpi7Meta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: catalogueGedVjLBASkMeta?.name ?? "catalogue",
    path: catalogueGedVjLBASkMeta?.path ?? "/catalogue",
    meta: { ...(catalogueGedVjLBASkMeta || {}), ...{"middleware":["client"]} },
    alias: catalogueGedVjLBASkMeta?.alias || [],
    redirect: catalogueGedVjLBASkMeta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/catalogue.vue").then(m => m.default || m)
  },
  {
    name: indexSoSmltHuwuMeta?.name ?? "checkout",
    path: indexSoSmltHuwuMeta?.path ?? "/checkout",
    meta: { ...(indexSoSmltHuwuMeta || {}), ...{"middleware":["client"]} },
    alias: indexSoSmltHuwuMeta?.alias || [],
    redirect: indexSoSmltHuwuMeta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: paymentdza2Cg5WMfMeta?.name ?? "checkout-payment",
    path: paymentdza2Cg5WMfMeta?.path ?? "/checkout/payment",
    meta: { ...(paymentdza2Cg5WMfMeta || {}), ...{"middleware":["client"]} },
    alias: paymentdza2Cg5WMfMeta?.alias || [],
    redirect: paymentdza2Cg5WMfMeta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/checkout/payment.vue").then(m => m.default || m)
  },
  {
    name: successvLFry5vvdNMeta?.name ?? "checkout-success",
    path: successvLFry5vvdNMeta?.path ?? "/checkout/success",
    meta: { ...(successvLFry5vvdNMeta || {}), ...{"middleware":["client"]} },
    alias: successvLFry5vvdNMeta?.alias || [],
    redirect: successvLFry5vvdNMeta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/checkout/success.vue").then(m => m.default || m)
  },
  {
    name: contactn86EENxh3HMeta?.name ?? "contact",
    path: contactn86EENxh3HMeta?.path ?? "/contact",
    meta: { ...(contactn86EENxh3HMeta || {}), ...{"middleware":["client"]} },
    alias: contactn86EENxh3HMeta?.alias || [],
    redirect: contactn86EENxh3HMeta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: deliveryPolicyXMazQGrmYuMeta?.name ?? "deliveryPolicy",
    path: deliveryPolicyXMazQGrmYuMeta?.path ?? "/deliveryPolicy",
    meta: { ...(deliveryPolicyXMazQGrmYuMeta || {}), ...{"middleware":["client"]} },
    alias: deliveryPolicyXMazQGrmYuMeta?.alias || [],
    redirect: deliveryPolicyXMazQGrmYuMeta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/deliveryPolicy.vue").then(m => m.default || m)
  },
  {
    name: indexDaCA8EY6VlMeta?.name ?? "favorites",
    path: indexDaCA8EY6VlMeta?.path ?? "/favorites",
    meta: { ...(indexDaCA8EY6VlMeta || {}), ...{"middleware":["client"]} },
    alias: indexDaCA8EY6VlMeta?.alias || [],
    redirect: indexDaCA8EY6VlMeta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: indexG2Hom0pSxnMeta?.name ?? "index",
    path: indexG2Hom0pSxnMeta?.path ?? "/",
    meta: { ...(indexG2Hom0pSxnMeta || {}), ...{"middleware":["client"]} },
    alias: indexG2Hom0pSxnMeta?.alias || [],
    redirect: indexG2Hom0pSxnMeta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexoB3FwamzokMeta?.name ?? "login",
    path: indexoB3FwamzokMeta?.path ?? "/login",
    meta: { ...(indexoB3FwamzokMeta || {}), ...{"middleware":["client"]} },
    alias: indexoB3FwamzokMeta?.alias || [],
    redirect: indexoB3FwamzokMeta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: reset_45doHP7aU9ROyIMeta?.name ?? "login-reset-do",
    path: reset_45doHP7aU9ROyIMeta?.path ?? "/login/reset-do",
    meta: { ...(reset_45doHP7aU9ROyIMeta || {}), ...{"middleware":["client"]} },
    alias: reset_45doHP7aU9ROyIMeta?.alias || [],
    redirect: reset_45doHP7aU9ROyIMeta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/login/reset-do.vue").then(m => m.default || m)
  },
  {
    name: resetJQtWwMIZNhMeta?.name ?? "login-reset",
    path: resetJQtWwMIZNhMeta?.path ?? "/login/reset",
    meta: { ...(resetJQtWwMIZNhMeta || {}), ...{"middleware":["client"]} },
    alias: resetJQtWwMIZNhMeta?.alias || [],
    redirect: resetJQtWwMIZNhMeta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/login/reset.vue").then(m => m.default || m)
  },
  {
    name: logoutAeIVSfFEURMeta?.name ?? "logout",
    path: logoutAeIVSfFEURMeta?.path ?? "/logout",
    meta: { ...(logoutAeIVSfFEURMeta || {}), ...{"middleware":["client"]} },
    alias: logoutAeIVSfFEURMeta?.alias || [],
    redirect: logoutAeIVSfFEURMeta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: _202406_45shipmentalfEtJcQQvMeta?.name ?? "news-202406-shipment",
    path: _202406_45shipmentalfEtJcQQvMeta?.path ?? "/news/202406-shipment",
    meta: { ...(_202406_45shipmentalfEtJcQQvMeta || {}), ...{"middleware":["client"]} },
    alias: _202406_45shipmentalfEtJcQQvMeta?.alias || [],
    redirect: _202406_45shipmentalfEtJcQQvMeta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/news/202406-shipment.vue").then(m => m.default || m)
  },
  {
    name: cancelkr5VMQWZ4bMeta?.name ?? "payment-cancel",
    path: cancelkr5VMQWZ4bMeta?.path ?? "/payment/cancel",
    meta: { ...(cancelkr5VMQWZ4bMeta || {}), ...{"middleware":["client"]} },
    alias: cancelkr5VMQWZ4bMeta?.alias || [],
    redirect: cancelkr5VMQWZ4bMeta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/payment/cancel.vue").then(m => m.default || m)
  },
  {
    name: success49LJgJYvfwMeta?.name ?? "payment-success",
    path: success49LJgJYvfwMeta?.path ?? "/payment/success",
    meta: { ...(success49LJgJYvfwMeta || {}), ...{"middleware":["client"]} },
    alias: success49LJgJYvfwMeta?.alias || [],
    redirect: success49LJgJYvfwMeta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/payment/success.vue").then(m => m.default || m)
  },
  {
    name: privacyPolicywluBvwE0GrMeta?.name ?? "privacyPolicy",
    path: privacyPolicywluBvwE0GrMeta?.path ?? "/privacyPolicy",
    meta: { ...(privacyPolicywluBvwE0GrMeta || {}), ...{"middleware":["client"]} },
    alias: privacyPolicywluBvwE0GrMeta?.alias || [],
    redirect: privacyPolicywluBvwE0GrMeta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/privacyPolicy.vue").then(m => m.default || m)
  },
  {
    name: _91id_93XYSa8RkW9MMeta?.name ?? "products-id",
    path: _91id_93XYSa8RkW9MMeta?.path ?? "/products/:id()",
    meta: { ...(_91id_93XYSa8RkW9MMeta || {}), ...{"middleware":["client"]} },
    alias: _91id_93XYSa8RkW9MMeta?.alias || [],
    redirect: _91id_93XYSa8RkW9MMeta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/products/[id].vue").then(m => m.default || m)
  },
  {
    name: indexy3p1NavYh9Meta?.name ?? "products",
    path: indexy3p1NavYh9Meta?.path ?? "/products",
    meta: { ...(indexy3p1NavYh9Meta || {}), ...{"middleware":["client"]} },
    alias: indexy3p1NavYh9Meta?.alias || [],
    redirect: indexy3p1NavYh9Meta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexdbwMCTKwHmMeta?.name ?? "register",
    path: indexdbwMCTKwHmMeta?.path ?? "/register",
    meta: { ...(indexdbwMCTKwHmMeta || {}), ...{"middleware":["client"]} },
    alias: indexdbwMCTKwHmMeta?.alias || [],
    redirect: indexdbwMCTKwHmMeta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: returnPolicyy6Td5DHFP3Meta?.name ?? "returnPolicy",
    path: returnPolicyy6Td5DHFP3Meta?.path ?? "/returnPolicy",
    meta: { ...(returnPolicyy6Td5DHFP3Meta || {}), ...{"middleware":["client"]} },
    alias: returnPolicyy6Td5DHFP3Meta?.alias || [],
    redirect: returnPolicyy6Td5DHFP3Meta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/returnPolicy.vue").then(m => m.default || m)
  },
  {
    name: salestrKC3LrFUzMeta?.name ?? "sales",
    path: salestrKC3LrFUzMeta?.path ?? "/sales",
    meta: { ...(salestrKC3LrFUzMeta || {}), ...{"middleware":["client"]} },
    alias: salestrKC3LrFUzMeta?.alias || [],
    redirect: salestrKC3LrFUzMeta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/sales.vue").then(m => m.default || m)
  },
  {
    name: indexnRITrVonnxMeta?.name ?? "search",
    path: indexnRITrVonnxMeta?.path ?? "/search",
    meta: { ...(indexnRITrVonnxMeta || {}), ...{"middleware":["client"]} },
    alias: indexnRITrVonnxMeta?.alias || [],
    redirect: indexnRITrVonnxMeta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: termsOfUseegdIcZwAi1Meta?.name ?? "termsOfUse",
    path: termsOfUseegdIcZwAi1Meta?.path ?? "/termsOfUse",
    meta: { ...(termsOfUseegdIcZwAi1Meta || {}), ...{"middleware":["client"]} },
    alias: termsOfUseegdIcZwAi1Meta?.alias || [],
    redirect: termsOfUseegdIcZwAi1Meta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/termsOfUse.vue").then(m => m.default || m)
  },
  {
    name: tipsAdvicepOyZQ1ZgHmMeta?.name ?? "tipsAdvice",
    path: tipsAdvicepOyZQ1ZgHmMeta?.path ?? "/tipsAdvice",
    meta: { ...(tipsAdvicepOyZQ1ZgHmMeta || {}), ...{"middleware":["client"]} },
    alias: tipsAdvicepOyZQ1ZgHmMeta?.alias || [],
    redirect: tipsAdvicepOyZQ1ZgHmMeta?.redirect,
    component: () => import("/vercel/path0/apps/front2/pages/tipsAdvice.vue").then(m => m.default || m)
  }
]