import revive_payload_client_DPeZplkMIb from "/vercel/path0/apps/front2/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.13_@unocss+reset_5dn2se66p6z3i25vxejh3gni7y/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_igOtj8j9aH from "/vercel/path0/apps/front2/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.13_@unocss+reset_5dn2se66p6z3i25vxejh3gni7y/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_BO7LqQPmIF from "/vercel/path0/apps/front2/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.13_@unocss+reset_5dn2se66p6z3i25vxejh3gni7y/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_shwiqh7Eki from "/vercel/path0/apps/front2/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.13_@unocss+reset_5dn2se66p6z3i25vxejh3gni7y/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_VY0I6Eizzh from "/vercel/path0/apps/front2/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.13_@unocss+reset_5dn2se66p6z3i25vxejh3gni7y/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_CmbGQmCRHy from "/vercel/path0/apps/front2/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.18.0_vue@3.4.27/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/front2/.nuxt/components.plugin.mjs";
import prefetch_client_qBnmcUSrrA from "/vercel/path0/apps/front2/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.13_@unocss+reset_5dn2se66p6z3i25vxejh3gni7y/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_57ztTZbgBv from "/vercel/path0/apps/front2/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.13_@unocss+reset_5dn2se66p6z3i25vxejh3gni7y/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_blahybtbCz from "/vercel/path0/apps/front2/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.0_@pinia+nuxt@0.5.1_rollup@4.18.0_vue@3.4.27__pinia@2.1_runjj3gkcsudmegabsrbl22ft4/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import i18n_VfGcjrvSkj from "/vercel/path0/apps/front2/plugins/i18n.ts";
import lazyload_ehEjmux8k8 from "/vercel/path0/apps/front2/plugins/lazyload.ts";
export default [
  revive_payload_client_DPeZplkMIb,
  unhead_igOtj8j9aH,
  router_BO7LqQPmIF,
  payload_client_shwiqh7Eki,
  check_outdated_build_client_VY0I6Eizzh,
  plugin_vue3_CmbGQmCRHy,
  components_plugin_KR1HBZs4kY,
  prefetch_client_qBnmcUSrrA,
  chunk_reload_client_57ztTZbgBv,
  plugin_blahybtbCz,
  i18n_VfGcjrvSkj,
  lazyload_ehEjmux8k8
]